import { React } from "react";
import { Component, Fragment } from "react";
import axios from "axios";
import { axiosConfig } from "../../../config/utility";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import CreateIcon from "@material-ui/icons/Create";
import { connect } from "react-redux";

import { addSettings } from "../../../actions";
import {
  ListAgentRoles,
  ListAgentSubRoles,
} from "../../../config/agent_role_api_calls";

import $ from "jquery";
import DataTable from "../../../components/Tables/DataTable";
import { tableLoader } from "../../../components/ContentLoaders/table_loader";
import MsaWarning from "../ExtraComponents/MsaWarning";
import SettingsError from "../ExtraComponents/SettingsError";
import PageTitle from "../ExtraComponents/PageTitle";
import HelperClass from "../../../config/helperClass";
import { THEME_TEXT_COLOR, THEME_COLOR } from "../../../config/config";

import Typography from "@material-ui/core/Typography";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import HomeIcon from "@material-ui/icons/Home";
import dateFormat, { masks } from "dateformat";

const auth = new HelperClass();

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

class AgentRoles extends Component {
  constructor() {
    super();
    this.state = {
      listRoleData: [],
      listSubRoleData: [],
      errorMessaged: "",
      errorMessage: "",
      successMessaged: "",
      successMessage: "",
      title: "",
      name: "",
      logo: "",
      delete_id: "",
      description: "",
      tableLoader: true,
      domain: "",
      step: 1,
      assigned_services: [],
      subRoleColumns: [],
      rolecolumns: [
        { name: "Title" },
        { name: "Link" },
        { name: "Role Icon" },
        { name: "Role Sequence " },
        {
          name: "status",
          options: {
            customBodyRender: (value, tableMeta) => {
              return <div>{this.roleStatus(value, tableMeta)}</div>;
            },
          },
        },
        {
          name: "Default Tab",
          options: {
            customBodyRender: (value, tableMeta) => {
              return <div>{this.roleDefault(value, tableMeta)}</div>;
            },
          },
        },
        {
          name: "Actions",
          options: {
            customBodyRender: (value, tableMeta) => {
              return (
                <div>
                  <div
                    className="nk-tb-col nk-tb-col-tools"
                    style={{ padding: "0px" }}
                  >
                    <ul className="nk-tb-actions gx-1">
                      <li>
                        <div className="drodown">
                          <a
                            href="#"
                            className="dropdown-toggle btn btn-icon btn-outline-light"
                            data-toggle="dropdown"
                          >
                            <em className="icon ni ni-more-h"></em>
                          </a>
                          <div className="dropdown-menu dropdown-menu-right">
                            <ul className="link-list-opt no-bdr">
                              <li>
                                <a
                                  data-toggle="tooltip"
                                  data-placement="left"
                                  onClick={() =>
                                    this.addMenuHistory(
                                      "/agent_roles/edit_agent_role/" +
                                        tableMeta.rowData[6]
                                    )
                                  }
                                  title="Edit Email"
                                  style={{ cursor: "pointer" }}
                                >
                                  <em className="icon ni ni-edit"></em>
                                  <span> Edit Role </span>
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              );
            },
          },
        },
      ],
      tableData: [],
    };
  }

  async componentDidMount() {
    this.setState({
      tableLoader: true,
    });
    const listAgentResponce = await ListAgentRoles(
      auth.getAccount(),
      auth.getToken()
    );

    console.log("ListAgentRoles Res: ", listAgentResponce.data);
    if (
      listAgentResponce.data.status === 403 ||
      listAgentResponce.data.errors === "authentication missing" ||
      listAgentResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (listAgentResponce.data.status === 404) {
      //window.location.replace("/error");
    } else if (
      listAgentResponce.data.status === 200 &&
      listAgentResponce.data.message === "success"
    ) {
      this.setState({
        listRoleData: listAgentResponce.data.data,
        tableLoader: false,
      });
    } else {
      //window.location.replace("/error");
    }
  }

  roleStatus = (value, tableMeta) => {
    let active = tableMeta.rowData[4];
    if (active === 1) {
      return (
        <span className="badge badge-outline-success">
          <em className="icon ni ni-done"></em> Active
        </span>
      );
    } else {
      return (
        <span className="badge badge-outline-primary">
          <em className="icon ni ni-na"></em> In Active
        </span>
      );
    }
  };

  roleDefault = (value, tableMeta) => {
    let active = tableMeta.rowData[5];
    if (active === 1) {
      return (
        <span className="badge badge-outline-success">
          <em className="icon ni ni-done"></em> Yes
        </span>
      );
    } else {
      return (
        <span className="badge badge-outline-primary">
          <em className="icon ni ni-na"></em> No
        </span>
      );
    }
  };

  subRoleStatus = (value, tableMeta) => {
    let active = tableMeta.rowData[5];
    if (active === 1) {
      return (
        <span className="badge badge-outline-success">
          <em className="icon ni ni-done"></em> Active
        </span>
      );
    } else {
      return (
        <span className="badge badge-outline-primary">
          <em className="icon ni ni-na"></em> In Active
        </span>
      );
    }
  };

  subRoleDefault = (value, tableMeta) => {
    let active = tableMeta.rowData[6];
    if (active === 1) {
      return (
        <span className="badge badge-outline-success">
          <em className="icon ni ni-done"></em> Yes
        </span>
      );
    } else {
      return (
        <span className="badge badge-outline-primary">
          <em className="icon ni ni-na"></em> No
        </span>
      );
    }
  };

  addMenuHistory = (link) => {
    this.props.props.history.push(link);
  };

  getAgentEmails = async () => {
    this.setState({
      tableLoader: true,
    });
    const listAgentSubRoleResponce = await ListAgentSubRoles(
      auth.getAccount(),
      auth.getToken()
    );

    console.log("agent sub roles  Res: ", listAgentSubRoleResponce.data);
    if (
      listAgentSubRoleResponce.data.status === 403 ||
      listAgentSubRoleResponce.data.errors === "authentication missing" ||
      listAgentSubRoleResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (listAgentSubRoleResponce.data.status === 404) {
      //window.location.replace("/error");
    } else if (
      listAgentSubRoleResponce.data.status === 200 &&
      listAgentSubRoleResponce.data.message === "success"
    ) {
      this.setState({
        listSubRoleData: listAgentSubRoleResponce.data.data,
        tableLoader: false,
        subRoleColumns: [
          { name: "Title" },
          { name: "Link" },
          { name: "Role Icon" },
          { name: "Role Sequence " },
          { name: "Parent Role " },
          {
            name: "status",
            options: {
              customBodyRender: (value, tableMeta) => {
                return <div>{this.subRoleStatus(value, tableMeta)}</div>;
              },
            },
          },
          {
            name: "Default Tab",
            options: {
              customBodyRender: (value, tableMeta) => {
                return <div>{this.subRoleDefault(value, tableMeta)}</div>;
              },
            },
          },
          {
            name: "Actions",
            options: {
              customBodyRender: (value, tableMeta) => {
                return (
                  <div>
                    <div
                      className="nk-tb-col nk-tb-col-tools"
                      style={{ padding: "0px" }}
                    >
                      <ul className="nk-tb-actions gx-1">
                        <li>
                          <div className="drodown">
                            <a
                              href="#"
                              className="dropdown-toggle btn btn-icon btn-outline-light"
                              data-toggle="dropdown"
                            >
                              <em className="icon ni ni-more-h"></em>
                            </a>
                            <div className="dropdown-menu dropdown-menu-right">
                              <ul className="link-list-opt no-bdr">
                                <li>
                                  <a
                                    data-toggle="tooltip"
                                    data-placement="left"
                                    onClick={() =>
                                      this.addMenuHistory(
                                        "/agent_roles/edit_agent_subrole/" +
                                          tableMeta.rowData[7]
                                      )
                                    }
                                    title="Edit Email"
                                    style={{ cursor: "pointer" }}
                                  >
                                    <em className="icon ni ni-edit"></em>
                                    <span> Edit Sub Role </span>
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                );
              },
            },
          },
        ],
      });
    } else {
      //window.location.replace("/error");
    }
  };

  render() {
    return (
      <div className="nk-content ">
        <div className="container-fluid">
          {this.props.defaultSettings === "missing" ? <SettingsError /> : null}
          {/* MSA WARNING  START*/}
          {this.props.is_msa_signed === "No" ? <MsaWarning /> : null}
          {/* MODAL EDIT PROFILE START */}
          {/* MSA WARNING END */}
          <div className="nk-block-head nk-block-head-sm">
            <div className="nk-block-between">
              <div className="nk-block-head-content">
                <h3 className="nk-block-title page-title">
                  <PageTitle name="Agent Roles" icon="icon ni ni-grid-alt" />
                </h3>
              </div>
              <div className="nk-block-head-content" id="HeadContent">
                <div className="toggle-wrap nk-block-tools-toggle">
                  <a
                    href="#"
                    className="btn btn-icon btn-trigger toggle-expand mr-n1"
                    data-target="pageMenu"
                  >
                    <em className="icon ni ni-more-v"></em>
                  </a>
                  <div className="dropdown">
                    <a
                      href="#"
                      className="btn btn-large btn-primary"
                      data-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <span>Add New Role</span>
                      <em className="icon ni ni-chevron-down"></em>
                    </a>
                    <div className="dropdown-menu dropdown-menu-right dropdown-menu-auto mt-1">
                      <ul className="link-list-plain">
                        <li>
                          <a
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              this.addMenuHistory("/agent_roles/add_agent_role/")
                            }
                          >
                            <span>
                              <em className="icon ni ni-plus"></em>  New Role
                            </span>
                          </a>
                        </li>
                        <li>
                          <a
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              this.addMenuHistory("/agent_roles/add_agent_subrole/")
                            }
                          >
                            <span>
                              <em className="icon ni ni-plus"></em>  New  Sub Role
                            </span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="nk-block">
            {/* Table 5th Col Start */}
            <div className="row g-gs">
              <div className="col-xxl-12">
                {this.state.errorMessage !== "" ? (
                  <div className="example-alert">
                    <div className="alert alert-pro alert-danger">
                      <div className="alert-text">
                        <h4>Error</h4>
                        <p>{this.state.errorMessage}</p>
                      </div>
                    </div>
                  </div>
                ) : null}
                {this.state.successMessage !== "" ? (
                  <div className="example-alert">
                    <div className="alert alert-pro alert-success">
                      <div className="alert-text">
                        <h4>Success</h4>
                        <p>{this.state.successMessage}</p>
                      </div>
                    </div>
                  </div>
                ) : null}
                <div className="nk-block nk-block-lg">
                  <div className="row g-gs">
                    <div className="col-xxl-12">
                      <div className="nk-block nk-block-lg">
                        <div className="card card-bordered">
                          <div className="card-inner">
                            <ul className="nav nav-tabs">
                              <li className="nav-item">
                                <a
                                  className="nav-link active"
                                  data-toggle="tab"
                                  href="#adminEmails"
                                  onClick={() => this.componentDidMount()}
                                >
                                  <em class="icon ni ni-grid-c"></em>
                                  <span>Roles </span>
                                </a>
                              </li>
                              <li className="nav-item">
                                <a
                                  className="nav-link"
                                  data-toggle="tab"
                                  href="#agentEmails"
                                  onClick={() => this.getAgentEmails()}
                                >
                                  <em class="icon ni ni-grid-c"></em>
                                  <span>Sub Roles</span>
                                </a>
                              </li>
                            </ul>
                            <div className="tab-content">
                              <div className="tab-pane active" id="adminEmails">
                                {this.state.tableLoader === true ? (
                                  tableLoader()
                                ) : (
                                  <DataTable
                                    columns={this.state.rolecolumns}
                                    tableData={this.state.listRoleData}
                                    title="Agent Roles"
                                  />
                                )}
                              </div>
                              <div className="tab-pane" id="agentEmails">
                                {this.state.tableLoader === true ? (
                                  tableLoader()
                                ) : (
                                  <DataTable
                                    columns={this.state.subRoleColumns}
                                    tableData={this.state.listSubRoleData}
                                    title="Agent Sub Roles"
                                  />
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Table 5th Col End */}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    defaultSettings: state.settings,
    is_msa_signed: state.is_msa_signed,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    addSettings: (payload) => dispatch(addSettings(payload)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(AgentRoles);
